import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import FilterList from "@material-ui/icons/FilterList";
import MenuIcon from "@material-ui/icons/Menu";
import * as React from "react";

import styled from "~/components/core/styled";
import AccountLayoutContext from "~/components/layouts/AccountLayout/AccountLayoutContext";

interface Props {
  onClick: () => void;
}

const AccountAppBar: React.FC<Props> = ({
  onClick
}: Props): JSX.Element | null => {
  const { mobileView, onToggleFiltersDrawer } = React.useContext(
    AccountLayoutContext
  );

  if (!mobileView) {
    return null;
  }

  return (
    <StyledAppBar position="sticky">
      <Toolbar>
        <IconButton onClick={onClick} color="inherit">
          <MenuIcon />
        </IconButton>
        <Title variant="h6">Pear Deck Tutor</Title>
        <IconButton onClick={onToggleFiltersDrawer} color="inherit">
          <FilterList />
        </IconButton>
      </Toolbar>
    </StyledAppBar>
  );
};

export default AccountAppBar;

const StyledAppBar = styled(AppBar)`
  display: flex;
  padding-right: 10px;
`;

const Title = styled(Typography)`
  flex: 1;
`;
