import NextLink from "next/link";
import * as React from "react";

import LogotypeFull from "~/components/core/LogotypeFull";
import styled from "~/components/core/styled";
import AccountLayoutContext from "~/components/layouts/AccountLayout/AccountLayoutContext";
import ROUTES from "~/constants/routes";
import { useLinkWithUmbrella } from "~/utils/useLinkWithUmbrella";

import { AccountMenuDivider } from "./styled";

const PearLauncher: React.FC = (): JSX.Element => {
  return <PearWrapper id="psi_launcher" />;
};

const AccountMenuLogotype: React.FC = (): JSX.Element => {
  const { sideMenuMinimized } = React.useContext(AccountLayoutContext);

  const linkWithUmbrella = useLinkWithUmbrella(ROUTES.home);

  return (
    <>
      <Wrapper>
        {sideMenuMinimized ? (
          <PearLauncher />
        ) : (
          <>
            <PearLauncher />
            <NextLink href={linkWithUmbrella || ROUTES.home} passHref>
              <a>
                <LogotypeFull />
              </a>
            </NextLink>
          </>
        )}
      </Wrapper>
      <AccountMenuDivider />
    </>
  );
};

export default AccountMenuLogotype;

const Wrapper = styled.div`
  display: flex;
  gap: 5px;
  padding: 29px 0 16px 16px;
  width: 100%;
`;

const PearWrapper = styled.div`
  margin-top: -6px;
  #psi_launcher_frame {
    left: 16px;
    position: fixed;
    top: 60px;
  }
`;
