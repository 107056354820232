import Drawer, { DrawerProps } from "@material-ui/core/Drawer";
import * as React from "react";

import styled from "~/components/core/styled";
import AccountLayoutContext from "~/components/layouts/AccountLayout/AccountLayoutContext";
import Theme from "~/declarations/theme";

import AccountAppBar from "./AccountAppBar";
import AccountMenu from "./AccountMenu";
import AccountMenuLogotype from "./AccountMenuLogotype";
import AccountMenuNeedHelp from "./AccountMenuNeedHelp";

const MOBILE_VIEW_POSITIVE = "mobileview";
const MINIMIZED_POSITIVE = "minimized";

const AccountSideMenu: React.FC = (): JSX.Element => {
  const {
    mobileView,
    sideMenuMinimized,
    onTriggerViewSideMenu
  } = React.useContext(AccountLayoutContext);
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    mobileView && sideMenuMinimized && onTriggerViewSideMenu?.();
  }, [mobileView, sideMenuMinimized, onTriggerViewSideMenu]);

  const handleOpenMenu = (): void => setOpen(true);

  const handleCloseMenu = (): void => setOpen(false);

  return (
    <>
      <AccountAppBar onClick={handleOpenMenu} />
      <Wrapper
        mobileview={mobileView ? MOBILE_VIEW_POSITIVE : ""}
        variant={mobileView ? "temporary" : "persistent"}
        minimized={!mobileView && sideMenuMinimized ? MINIMIZED_POSITIVE : ""}
        open={mobileView ? open : true}
        onClose={handleCloseMenu}
        PaperProps={{ elevation: 0 }}
      >
        <AccountMenuLogotype />
        <AccountMenu />
        <AccountMenuNeedHelp />
      </Wrapper>
    </>
  );
};

export default AccountSideMenu;

interface WrapperProps {
  minimized: string;
  mobileview: string;
}

interface WrapperStyledProps extends WrapperProps {
  theme: Theme;
}

const getSideMenuWith = ({ minimized, theme }: WrapperStyledProps): number => {
  const sidebarWidth = theme.layouts.account.sidebarWidth;
  return minimized === MINIMIZED_POSITIVE ? sidebarWidth.min : sidebarWidth.max;
};

const Wrapper = styled(Drawer)<WrapperProps & DrawerProps>`
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  width: ${getSideMenuWith}px;

  .MuiPaper-root {
    background-color: ${({ mobileview, theme }): string =>
      mobileview === MOBILE_VIEW_POSITIVE
        ? theme.palette.primary.main
        : "transparent"};
    border-right: none;
    overflow: hidden;
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    width: ${getSideMenuWith}px;
  }
`;
