import Typography from "@material-ui/core/Typography";
import * as React from "react";

import styled from "~/components/core/styled";
import useHead from "~/components/layouts/common/useHead";

const AccountHeaderCaption: React.FC = (): JSX.Element => {
  const head = useHead({
    addAppPrefix: false
  });

  return <Caption variant="h4">{head}</Caption>;
};

export default AccountHeaderCaption;

const Caption = styled(Typography)`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  font-weight: 900;
  max-width: 66%;
  overflow: hidden;

  @media (max-width: ${({ theme }): number => theme.breakpoints.values.md}px) {
    font-size: 28px;
  }

  @media (max-width: ${({ theme }): number => theme.breakpoints.values.sm}px) {
    font-size: 14px;
  }
`;
