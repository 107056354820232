// import { useRouter } from "next/router";
import { useMemo } from "react";

// import { UMBRELLA } from "~/constants/common";

interface UseLinkWithUmbrellaState {
  pathname: string;
  query?: {
    umbrella?: string;
  };
}

export function useLinkWithUmbrella(
  href?: string,
  additionQuery?: {}
): UseLinkWithUmbrellaState | null {
  // const umbrellaFromLS = useMemo(() => localStorage.getItem(UMBRELLA), []);
  // const { query } = useRouter();

  // const umbrella = useMemo(() => {
  //   if (query.umbrella) {
  //     return query.umbrella as string;
  //   } else if (umbrellaFromLS) {
  //     return umbrellaFromLS as string;
  //   }

  //   return "";
  // }, [query.umbrella, umbrellaFromLS]);

  return useMemo(() => {
    if (!href) return null;

    return {
      pathname: href,
      query: additionQuery ?? {}
    };
  }, [additionQuery, href]);
}
