import Paper from "@material-ui/core/Paper";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { SnackbarProvider } from "notistack";
import * as React from "react";

import { useRequiredAuthContext } from "~/components/auth/AuthWrapper";
import styled from "~/components/core/styled";
import ErrorBoundary from "~/components/providers/ErrorBoundary";
import { setCachedWeekStart } from "~/utils/cachedWeekStart";
import { useAuthenticatedFetch } from "~/utils/http";

import LayoutHead from "../common/LayoutHead";
import AccountHeader from "./AccountHeader";
import AccountContext from "./AccountLayoutContext";
import AccountSideMenu from "./AccountSideMenu";

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

const LS_MENU_MINIMIZED_KEY = "sideMenuMinimized";
const MOBILE_VIEW_POSITIVE = "mobileview";

const AccountLayout: React.FC<Props> = ({ children }: Props): JSX.Element => {
  const {
    user: { weekStart }
  } = useRequiredAuthContext();

  const lsSideMenuMinimized = localStorage.getItem(LS_MENU_MINIMIZED_KEY);
  const theme = useTheme();
  const mobileView = useMediaQuery<boolean>(theme.breakpoints.down("xs"));
  const sideMenuMinimizedInit = !["", null].includes(lsSideMenuMinimized)
    ? JSON.parse(lsSideMenuMinimized as string)
    : mobileView;
  const [sideMenuMinimized, setSideMenuMinimized] = React.useState<boolean>(
    sideMenuMinimizedInit
  );

  const subjects = useAuthenticatedFetch("/api/v1/subjects/", {}, true);

  const [filtersDrawerOpen, setFiltersDrawerOpen] = React.useState(false);

  React.useEffect(() => {
    setCachedWeekStart(weekStart);
  }, [weekStart]);

  React.useEffect((): void => {
    if (mobileView) {
      setSideMenuMinimized(mobileView);
      localStorage.setItem(LS_MENU_MINIMIZED_KEY, mobileView.toString());
    }
  }, [mobileView]);

  const handleTriggerViewSideMenu = (): void => {
    const newMinimizedValue = !sideMenuMinimized;
    setSideMenuMinimized(newMinimizedValue);
    localStorage.setItem(LS_MENU_MINIMIZED_KEY, newMinimizedValue.toString());
  };

  const handleToggleFiltersDrawer = (): void => {
    setFiltersDrawerOpen(!filtersDrawerOpen);
  };

  return (
    <Wrapper mobileview={mobileView ? MOBILE_VIEW_POSITIVE : ""}>
      <AccountContext.Provider
        value={{
          mobileView,
          sideMenuMinimized,
          filtersDrawerOpen,
          subjects,
          onToggleFiltersDrawer: handleToggleFiltersDrawer,
          onTriggerViewSideMenu: handleTriggerViewSideMenu
        }}
      >
        <LayoutHead />
        <SnackbarProvider
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
        >
          <AccountSideMenu />
          <InnerWrapper mobileview={mobileView ? MOBILE_VIEW_POSITIVE : ""}>
            <AccountHeader />
            <Content>
              <ErrorBoundary>{children}</ErrorBoundary>
            </Content>
          </InnerWrapper>
        </SnackbarProvider>
      </AccountContext.Provider>
    </Wrapper>
  );
};

export default AccountLayout;

interface WrapperProps {
  mobileview: string;
}

const Wrapper = styled.div<WrapperProps>`
  background-color: ${({ theme }): string => theme.palette.primary.main};
  display: flex;
  flex-direction: ${({ mobileview }): string =>
    mobileview === MOBILE_VIEW_POSITIVE ? "column" : "row"};
`;

const InnerWrapper = styled(Paper)<WrapperProps>`
  border-radius: ${({ theme }): number =>
    theme.layouts.account.content.borderRadius}px;
  display: flex;
  flex-direction: column;
  margin: 20px 20px 20px 0;
  min-height: calc(100vh - 20px * 2);
  width: ${({ mobileview }): string =>
    mobileview === MOBILE_VIEW_POSITIVE ? "100%" : "calc(100% - 90px)"};
`;

const Content = styled.main`
  background-color: ${({ theme }): string => theme.palette.grey[50]};
  border-radius: ${({ theme }): string => {
    const borderRadius = theme.layouts.account.content.borderRadius;
    return `0 0 ${borderRadius}px ${borderRadius}px`;
  }};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: ${({ theme }): string => {
    const padding = theme.layouts.account.content.padding;
    return `0 ${padding}px ${padding}px`;
  }};

  @media (max-width: ${({ theme }): number => theme.breakpoints.values.sm}px) {
    padding: 0;
    margin-right: 6px;
  }
`;
