import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import { default as NextLink } from "next/link";
import * as React from "react";

import { useRequiredAuthContext } from "~/components/auth/AuthWrapper";
import styled from "~/components/core/styled";
import { PEAR_PRIVACY_URL, PEAR_TERMS_URL } from "~/constants/api";
import ROUTES from "~/constants/routes";

import AccountUserTrigger from "./AccountUserTrigger";

interface Props {
  anchorEl: HTMLButtonElement | null;
  onClose: () => void;
}

const AccountUserMenu: React.FC<Props> = ({
  anchorEl,
  onClose
}: Props): JSX.Element => {
  const {
    signOut,
    user: { id: userId }
  } = useRequiredAuthContext();
  return (
    <Wrapper
      open={Boolean(anchorEl)}
      elevation={2}
      anchorEl={anchorEl}
      onClose={onClose}
      disableScrollLock={true}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left"
      }}
    >
      <AccountUserTrigger onClick={onClose} />
      <MenuList>
        {userId !== -1 && (
          <MenuItem>
            <NextLink href={ROUTES.settings}>
              <a>Edit Profile</a>
            </NextLink>
          </MenuItem>
        )}
        <MenuItem onClick={signOut}>Sign Out</MenuItem>
        <MenuItem>
          <Typography component="a" target="_blank" href={PEAR_PRIVACY_URL}>
            Product Privacy Policy
          </Typography>
        </MenuItem>
        <MenuItem>
          <Typography component="a" target="_blank" href={PEAR_TERMS_URL}>
            Product Terms of Service
          </Typography>
        </MenuItem>
      </MenuList>
    </Wrapper>
  );
};

export default AccountUserMenu;

const Wrapper = styled(Popover)`
  .MuiPaper-root {
    border-radius: 15px;
  }
`;

const MenuList = styled.ul`
  list-style: none;
  margin: 10px 0;
  padding: 0;
`;
