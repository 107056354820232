import List from "@material-ui/core/List";
import * as React from "react";

// import GraphIcon from "~/assets/icons/Graph.svg?sprite";
import HomeIcon from "~/assets/icons/Home.svg?sprite";
import NotebookIcon from "~/assets/icons/Notebook2.svg?sprite";
// import ReportIcon from "~/assets/icons/Report.svg?sprite";
import SettingsIcon from "~/assets/icons/Settings.svg?sprite";
import StudentsIcon from "~/assets/icons/Students.svg?sprite";
import WritingLabIcon from "~/assets/icons/WritingLab.svg?sprite";
import { AuthContext } from "~/components/auth/AuthWrapper";
import styled from "~/components/core/styled";
import ROUTES from "~/constants/routes";
import { PERMISSION_CODENAME } from "~/declarations/models/User";

import AccountMenuItem from "./AccountMenuItem";
import AccountMenuItemViewTrigger from "./AccountMenuItem/AccountMenuItemViewTrigger";

interface MenuItem {
  href: string;
  title: string;
  Icon: React.FC;
  codename?: PERMISSION_CODENAME;
}

const MENU_ITEMS: MenuItem[] = [
  {
    href: ROUTES.home,
    title: "Dashboard",
    Icon: HomeIcon,
    codename: PERMISSION_CODENAME.dashboard
  },
  {
    href: ROUTES.lessons,
    title: "Live tutoring",
    Icon: NotebookIcon,
    codename: PERMISSION_CODENAME.lessons
  },
  {
    href: ROUTES.writingLabs,
    title: "Writing Lab",
    Icon: WritingLabIcon,
    codename: PERMISSION_CODENAME.writingLabs
  },
  {
    href: ROUTES.students,
    title: "Students",
    Icon: StudentsIcon,
    codename: PERMISSION_CODENAME.students
  },
  // {
  //   href: ROUTES.reports,
  //   title: "Reports",
  //   Icon: ReportIcon,
  //   codename: PERMISSION_CODENAME.reports
  // },
  // TODO: temporarily removed the menu items
  // {
  //   href: ROUTES.trending,
  //   title: "Trending",
  //   Icon: GraphIcon,
  //   codename: PERMISSION_CODENAME.trending
  // },
  {
    href: ROUTES.settings,
    title: "Settings",
    Icon: SettingsIcon
  }
];

const TEACHER_ITEMS = MENU_ITEMS.filter(x => x.href !== ROUTES.settings);

const AccountMenu: React.FC = (): JSX.Element => {
  const authContext = React.useContext(AuthContext);
  let userId: number | undefined;
  if (authContext) {
    userId = authContext.user.id;
  }

  return (
    <StyledMenu>
      <AccountMenuItemViewTrigger />
      {(userId === -1 ? TEACHER_ITEMS : MENU_ITEMS).map(
        ({ title, ...menuItem }) => (
          <AccountMenuItem key={title} title={title} {...menuItem} />
        )
      )}
    </StyledMenu>
  );
};

export default AccountMenu;

const StyledMenu = styled(List)`
  flex-grow: 1;
`;
