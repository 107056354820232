import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import ListItem, { ListItemProps } from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import NextLink from "next/link";
import * as React from "react";

import styled from "~/components/core/styled";
import { hexToRgba } from "~/utils/style";
import { useLinkWithUmbrella } from "~/utils/useLinkWithUmbrella";

interface Props {
  href: string;
  title: string;
  Icon: React.FC;
  selected?: boolean;
}

const AccountMenuItemMinimized: React.FC<Props> = ({
  title,
  Icon,
  href,
  selected = false
}: Props): JSX.Element => {
  const linkWithUmbrella = useLinkWithUmbrella(href);

  return (
    <Tooltip title={title} placement="right" interactive>
      <StyledListItem dense>
        <NextLink href={linkWithUmbrella || href}>
          <a href={href}>
            <ItemButton selected={selected}>
              <Icon />
            </ItemButton>
          </a>
        </NextLink>
      </StyledListItem>
    </Tooltip>
  );
};

export default AccountMenuItemMinimized;

const StyledListItem = styled(ListItem)`
  display: flex;
  justify-content: center;
  padding: 2px 0;
` as React.ComponentType<ListItemProps>;

type ItemButtonProps = {
  selected: boolean;
};

const ItemButton = styled(IconButton)<ItemButtonProps & IconButtonProps>`
  background-color: ${({ theme, selected }): string =>
    selected ? hexToRgba(theme.palette.common.black, 0.14) : ""};
  color: ${({ theme }): string => theme.palette.common.white};
  margin: auto;

  svg {
    color: ${({ theme }): string => theme.palette.common.white};
    height: 20px;
    width: 20px;
  }

  &:hover {
    color: ${({ theme }): string => theme.palette.secondary.main};

    svg {
      color: ${({ theme }): string => theme.palette.secondary.main};
    }
  }
`;
