import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import NextLink from "next/link";
import * as React from "react";

import styled from "~/components/core/styled";
import { useLinkWithUmbrella } from "~/utils/useLinkWithUmbrella";

interface Props {
  href: string;
  title: string;
  Icon: React.FC;
  selected?: boolean;
}

const AccountMenuItemMaximized: React.FC<Props> = ({
  title,
  Icon,
  href,
  selected = false
}: Props): JSX.Element => {
  const linkWithUmbrella = useLinkWithUmbrella(href);

  return (
    <Wrapper>
      <NextLink href={linkWithUmbrella || href}>
        <a href={href}>
          <StyledListItem button selected={selected}>
            <StyledListItemIcon>
              <Icon />
            </StyledListItemIcon>
            <StyledListItemText>{title}</StyledListItemText>
          </StyledListItem>
        </a>
      </NextLink>
    </Wrapper>
  );
};

export default AccountMenuItemMaximized;

const Wrapper = styled.div`
  margin-left: 8px;
`;

const StyledListItem = styled(ListItem)`
  border-radius: 24px 0 0 24px;
  color: ${({ theme }): string => theme.palette.common.white};
  font-size: 14.5px;

  svg {
    color: ${({ theme }): string => theme.palette.common.white};
    height: 20px;
    width: 20px;
  }

  &:hover {
    svg {
      color: ${({ theme }): string => theme.palette.secondary.main};
    }
  }
`;

const StyledListItemIcon = styled(ListItemIcon)`
  height: 20px;
  margin-right: 17px;
  min-width: unset;
  width: 20px;
`;

const StyledListItemText = styled(ListItemText)`
  white-space: nowrap;
`;
