import { WeekStart } from "~/utils/cachedWeekStart";

import { UmbrellaAccount } from "./UmbrellaAccount";

export enum PERMISSION_CODENAME {
  manageUsers = "manage_users",
  dashboard = "view_dashboard",
  reports = "view_reports",
  lessons = "view_lessons",
  students = "view_students",
  trending = "view_trending",
  writingLabs = "view_writing_lab"
}

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  umbrellaAccounts: UmbrellaAccount[];
  introGuidePassed: boolean;
  groups: Group[];
  // profilePhoto: ProfilePhoto;
  userPermissions: UserPermission[];
  weekStart: WeekStart;
  isStaff: boolean;
}

export interface Group {
  id: number;
  name: string;
  permissions: UserPermission[];
}

export interface UserPermission {
  name: string;
  codename: PERMISSION_CODENAME;
}

// export interface ProfilePhoto {
//   fullSize?: string;
//   smallSquareCrop?: string;
//   mediumSquareCrop?: string;
//   bigSquareCrop?: string;
// }
