import * as React from "react";

import LogoFull from "~/assets/images/Logo_PearDeckTutor_white_noPear.svg?sprite";
import styled from "~/components/core/styled";

interface Props {
  className?: string;
}

const LogotypeFull: React.FC<Props> = ({ className }: Props): JSX.Element => (
  <Wrapper className={className}>
    <LogoWrapper>
      <Logo />
      <Formerly>Formerly TutorMe</Formerly>
      <SystemName>admin dashboard</SystemName>
    </LogoWrapper>
  </Wrapper>
);

export default LogotypeFull;

const Wrapper = styled.div`
  color: ${({ theme }): string => theme.palette.common.white};
  display: flex;
  font-size: 13px;
`;

const LogoWrapper = styled.span`
  display: inline-block;
`;

const SystemName = styled.div`
  font-weight: 600;
  letter-spacing: 0.45px;
  text-transform: uppercase;
`;

const Logo = styled(LogoFull)`
  height: 19px;
  width: 193px;
`;

const Formerly = styled.div`
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 9px;
`;
