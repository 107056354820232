import { Button, Typography } from "@material-ui/core";
import * as React from "react";

import { useRequiredAuthContext } from "~/components/auth/AuthWrapper";
import styled from "~/components/core/styled";

import AccountUserInstitutions from "./AccountUserInstitutions";

interface Props {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const AccountUserTrigger: React.FC<Props> = ({
  onClick
}: Props): JSX.Element => {
  const { user } = useRequiredAuthContext();

  return (
    <Trigger onClick={onClick} color="primary">
      <UserData>
        <UserName variant="h6">
          {`${user?.firstName} ${user?.lastName}`}
        </UserName>
        <AccountUserInstitutions />
      </UserData>
    </Trigger>
  );
};

export default AccountUserTrigger;

const Trigger = styled(Button)`
  border: 1px solid ${({ theme }): string => theme.palette.grey["400"]};
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  min-width: 280px;
  padding: 12px 15px;
  text-align: initial;
  text-transform: unset;

  @media (max-width: ${({ theme }): number => theme.breakpoints.values.sm}px) {
    min-width: 230px;
  }
`;

const UserData = styled.div`
  height: 32px;
  margin-right: 10px;
`;

const UserName = styled(Typography)`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: ${({ theme }): string => theme.palette.common.black};
  display: -webkit-box;
  font-size: 16px;
  line-height: 1;
  overflow: hidden;
`;
