import Skeleton from "@material-ui/lab/Skeleton";
import { useRouter } from "next/router";
import * as React from "react";

import styled, { isPropValid } from "~/components/core/styled";
import AccountLayoutContext from "~/components/layouts/AccountLayout/AccountLayoutContext";
import { PERMISSION_CODENAME } from "~/declarations/models/User";

// import useAccessPermission from "~/utils/useAccessPermission";
import AccountMenuItemMaximized from "./AccountMenuItemMaximized";
import AccountMenuItemMinimized from "./AccountMenuItemMinimized";

interface Props {
  href: string;
  title: string;
  Icon: React.FC;
  codename?: PERMISSION_CODENAME;
}

const AccountMenuItem: React.FC<Props> = ({
  codename,
  ...rest
}: Props): JSX.Element | null => {
  const { sideMenuMinimized } = React.useContext(AccountLayoutContext);
  const { pathname } = useRouter();
  // const { loading, granted } = useAccessPermission(codename);
  const loading = false;
  const granted = true;

  const selected = pathname === rest.href;

  if (loading) {
    return <MenuItemStub compact={sideMenuMinimized} />;
  }

  if (!Boolean(codename) || granted) {
    return sideMenuMinimized ? (
      <AccountMenuItemMinimized {...rest} selected={selected} />
    ) : (
      <AccountMenuItemMaximized {...rest} selected={selected} />
    );
  }

  return null;
};

export default AccountMenuItem;

interface MenuItemStubProps {
  compact: boolean;
}

const MenuItemStub = styled(Skeleton, { shouldForwardProp: isPropValid })<
  MenuItemStubProps
>`
  border-radius: ${({ compact }): string =>
    compact ? "30px" : "30px 0 0 30px"};
  height: 47px;
  margin: 0 0 1px 8px;
  transform: none;
  width: ${({ compact }): string => (compact ? "54px" : "100%")};
`;
