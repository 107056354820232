import Paper from "@material-ui/core/Paper";
import * as React from "react";

import styled from "~/components/core/styled";

import AccountUserMenu from "./AccountUserMenu";
import AccountUserTrigger from "./AccountUserTrigger";

const AccountUser: React.FC = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClickMenuTrigger = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = (): void => {
    setAnchorEl(null);
  };

  return (
    <Wrapper elevation={0}>
      <AccountUserTrigger onClick={handleClickMenuTrigger} />
      <AccountUserMenu anchorEl={anchorEl} onClose={handleCloseMenu} />
    </Wrapper>
  );
};

export default AccountUser;

const Wrapper = styled(Paper)`
  border-radius: 15px;
  max-width: 75%;
`;
