import { useRouter } from "next/router";

import { useOptionalAuthContext } from "~/components/auth/AuthWrapper";
import { APP_NAME } from "~/constants/app";
import ROUTE_CAPTIONS from "~/constants/routeCaptions";
import { format } from "~/utils/strings";

interface UseHeadProps {
  addAppPrefix?: boolean;
}

export default function useHead({ addAppPrefix = true }: UseHeadProps): string {
  const authContext = useOptionalAuthContext();
  const userName = `${authContext?.user.firstName} ${authContext?.user.lastName}`;

  const router = useRouter();
  const routeCaption = ROUTE_CAPTIONS[router.pathname];
  const { id } = router.query;
  if (Array.isArray(id)) {
    throw new Error("invariant");
  }

  const specificHead = routeCaption
    ? format(routeCaption, { user: userName, id })
    : "";

  if (addAppPrefix) {
    return `${APP_NAME} ${specificHead}`;
  } else {
    return specificHead;
  }
}
