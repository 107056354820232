import AppBar from "@material-ui/core/AppBar";
import * as React from "react";

import styled from "~/components/core/styled";
import { theme } from "~/theme/theme";

import AccountHeaderCaption from "./AccountHeaderCaption";
import AccountUser from "./AccountUser";

const AccountHeader: React.FC = (): JSX.Element => {
  return (
    <Wrapper>
      <AccountHeaderCaption />
      <AccountUser />
    </Wrapper>
  );
};

export default AccountHeader;

const Wrapper = styled(AppBar)`
  align-items: center;
  background-color: transparent;
  box-shadow: unset;
  color: unset;
  display: flex;
  flex-direction: row;
  height: 101px;
  justify-content: space-between;
  padding: 0 30px !important;
  position: static;

  @media (max-width: ${({ theme }): number => theme.breakpoints.values.sm}px) {
    padding: 0 23px 0 12px !important;
    z-index: ${theme.zIndex.speedDial};
  }
`;
