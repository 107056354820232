import { css, SerializedStyles } from "@emotion/core";
import Link from "@material-ui/core/Link";
import * as React from "react";

import EnvelopeIcon from "~/assets/icons/Envelope.svg?sprite"; // TODO: change asset
import styled from "~/components/core/styled";
import AccountLayoutContext from "~/components/layouts/AccountLayout/AccountLayoutContext";
import { PARTNERS_EMAIL } from "~/constants/common";

import { AccountMenuDivider } from "./styled";

const SIDE_MENU_MINIMIZED_POSITIVE = "minimized";

const AccountMenuNeedHelp: React.FC = (): JSX.Element => {
  const { sideMenuMinimized } = React.useContext(AccountLayoutContext);

  return (
    <footer>
      <AccountMenuDivider />
      <Content
        minimized={sideMenuMinimized ? SIDE_MENU_MINIMIZED_POSITIVE : ""}
      >
        <Question>{sideMenuMinimized ? "-?-" : "Need help?"}</Question>
        <EmailLink href={`mailto:${PARTNERS_EMAIL}`} target="_blank">
          <EmailIcon
            minimized={sideMenuMinimized ? SIDE_MENU_MINIMIZED_POSITIVE : ""}
          />
          {sideMenuMinimized ? "" : "Contact us"}
        </EmailLink>
      </Content>
    </footer>
  );
};

export default AccountMenuNeedHelp;

interface ContentProps {
  minimized: string;
}

const Content = styled.div<ContentProps>`
  padding: 27px;

  ${({ minimized }: ContentProps): SerializedStyles | undefined => {
    if (minimized === SIDE_MENU_MINIMIZED_POSITIVE) {
      return css`
        padding: 27px 0;
        text-align: center;
      `;
    }
  }}
`;

const Question = styled.h5`
  color: ${({ theme }): string => theme.palette.primary.light};
  margin-top: 0;
  text-transform: uppercase;
`;

const EmailIcon = styled(EnvelopeIcon)`
  flex-shrink: 0;
  height: 20px;
  margin: ${({ minimized }): string =>
    minimized === SIDE_MENU_MINIMIZED_POSITIVE ? "auto" : "0 17px 0 0"};
  width: 20px;
`;

const EmailLink = styled(Link)`
  align-items: center;
  color: ${({ theme }): string => theme.palette.common.white} !important;
  display: flex;

  &:hover {
    color: ${({ theme }): string => theme.palette.secondary.main};

    svg {
      color: ${({ theme }): string => theme.palette.secondary.main};
    }
  }
`;
