import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import ListItem, { ListItemProps } from "@material-ui/core/ListItem";
// import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import * as React from "react";

import styled from "~/components/core/styled";
import AccountLayoutContext from "~/components/layouts/AccountLayout/AccountLayoutContext";
import Theme from "~/declarations/theme";

// const TITLES_BY_MINIMIZED: { [key: string]: string } = {
//   true: "Expand menu",
//   false: "Collapse menu"
// };

const SIDE_MENU_MINIMIZED_POSITIVE = "minimized";

const AccountMenuItemViewTrigger: React.FC = (): JSX.Element => {
  const { onTriggerViewSideMenu, sideMenuMinimized } = React.useContext(
    AccountLayoutContext
  );

  return (
    <StyledListItem dense>
      {!sideMenuMinimized && <UselessInscription>menu</UselessInscription>}
      {/* <Tooltip
        title={TITLES_BY_MINIMIZED[sideMenuMinimized.toString()]}
        enterDelay={500}
        placement="right"
      > */}
      <StyledIconButton
        onClick={onTriggerViewSideMenu}
        minimized={sideMenuMinimized ? SIDE_MENU_MINIMIZED_POSITIVE : ""}
      >
        {sideMenuMinimized ? <MenuIcon /> : <CloseIcon />}
      </StyledIconButton>
      {/* </Tooltip> */}
    </StyledListItem>
  );
};

export default AccountMenuItemViewTrigger;

const StyledListItem = styled(ListItem)`
  justify-content: space-between;
  padding: 0;
  text-align: right;
` as React.ComponentType<ListItemProps>;

const UselessInscription = styled.div`
  color: ${({ theme }): string => theme.palette.primary.light};
  font-size: 12px;
  margin-left: 24px;
  text-transform: uppercase;
`;

interface ButtonProps {
  minimized: string;
}

interface IconButtonStyledProps extends ButtonProps {
  theme: Theme;
}

const StyledIconButton = styled(IconButton)<ButtonProps & IconButtonProps>`
  color: ${({ theme }): string => theme.palette.primary.light};
  margin: ${({ minimized }: IconButtonStyledProps): string => {
    return minimized === SIDE_MENU_MINIMIZED_POSITIVE ? "auto" : "0 6px 0 0";
  }};

  &:hover {
    color: ${({ theme }): string => theme.palette.secondary.main};

    svg {
      color: ${({ theme }): string => theme.palette.secondary.main};
    }
  }
`;
