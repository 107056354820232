import ROUTES from "./routes";

export default {
  [ROUTES.home]: "Welcome, {{user}}!",
  [ROUTES.writingLabs]: "Paper Reviews",
  [ROUTES.lessons]: "Live Lessons",
  [ROUTES.students]: "Students",
  [ROUTES.reports]: "Reports",
  [ROUTES.trending]: "What's Trending",
  [ROUTES.settings]: "Settings",
  [ROUTES.auth.signup]: "Signup",
  [ROUTES.auth.passwordReset]: "Reset Password",
  "/lessons/[id]": "Lesson #{{id}} Details"
};
